import {AuthAction} from "./reducer";
import {AppThunkAction} from "../../app/store";
import * as types from "./constants";
import {LoginForm} from "./models";
import {customHistory} from "../../common/utils/history";
import {requestAuthentication, requestUserData} from "./api";
import {getAxiosErrorMessage} from "../../common/utils/functions";
import {addNotify} from "../../common/actions/notify";
import {requestDisableCompany, requestEnableCompany} from "../profile/api";
import {CompanyDisableForm, CompanyResponse, PartnerResponse} from "../profile/models";

export const login = (
  form: LoginForm,
): AppThunkAction<AuthAction> => dispatch => {
  
  dispatch({type: types.LOGIN_REQUEST});
  
  requestAuthentication(form)
    .then(({data}) => {
      localStorage.setItem('auth', JSON.stringify({authKey: data.authKey}));
      dispatch({type: types.LOGIN_SUCCESS, payload: {
        employee: data,
        // timestamp: Number(headers["x-request-timestamp"])
      }});
      
      customHistory.replace('/orders');
    })
    .catch((err) => {
      dispatch({type: types.LOGIN_FAILURE, payload: getAxiosErrorMessage(err)});
    })
}

export const logout = (): AppThunkAction<AuthAction> => dispatch => {
  localStorage.setItem('auth', JSON.stringify({authKey: null}));
  dispatch({type: types.LOGOUT});
  
  customHistory.replace('/login');
}

export const getUser = (
  expand = '',
): AppThunkAction<AuthAction> => dispatch => {
  dispatch({type: types.GET_USER_REQUEST});
  
  requestUserData({expand})
    .then(({data}) => {
      dispatch({type: types.GET_USER_SUCCESS, payload: {
        employee: data,
        // timestamp: Number(headers["x-request-timestamp"])
        }})
    })
    .catch((err) => {
      const errorMessage = getAxiosErrorMessage(err);
      
      const logout = err?.response?.status && err.response.status === 401;
      
      if (logout) {
        localStorage.setItem('auth', JSON.stringify({authKey: null}));
      }
      
      dispatch(addNotify(errorMessage));
      dispatch({
        type: types.GET_USER_FAILURE,
        payload: {
          error: errorMessage,
          logout,
        }
      })

      customHistory.replace('/login');
    })
}

/**
 * Включить заведение companyId.
 * При успешном ответе запроса также показать включение локально.
 */
export const enableCompany = (
  companyId: number,
): AppThunkAction<AuthAction> => dispatch => {
  dispatch({type: types.COMPANY_CHANGE_REQUEST});
  
  requestEnableCompany(companyId)
    .then(() => {
      dispatch({
        type: types.ENABLE_COMPANY,
        payload: companyId,
      })
    })
    .catch((err) => {
      dispatch({
          type: types.ENABLE_COMPANY_FAILURE
      });
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}

/**
 * Отключить заведение companyId.
 * При успешном ответе запроса также показать отключение локально.
 */
export const disableCompany = (
  companyId: number,
  form: CompanyDisableForm,
): AppThunkAction<AuthAction> => dispatch => {
  dispatch({type: types.COMPANY_CHANGE_REQUEST});
  
  requestDisableCompany(companyId, form)
    .then(() => {
      dispatch({
        type: types.DISABLE_COMPANY,
        payload: {companyId, dateTime: form.dateTime},
      })
    })
    .catch((err) => {
      dispatch({
        type: types.DISABLE_COMPANY_FAILURE
      });
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}

/**
 * Изменить выбранное заведение на заведение companyId
 */
export const changeCurrentCompany = (
  companyId: number,
): AppThunkAction<AuthAction> => dispatch => {
  dispatch({type: types.COMPANY_CHANGE_REQUEST});
  dispatch({
    type: types.CHANGE_CURRENT_COMPANY,
    payload: companyId,
  })
}

/**
 * Заменить текущий timestamp в стейте
 * @param timestamp
 */
export const changeTimestamp = (
    timestamp: number
): AppThunkAction<AuthAction> => dispatch => {
    dispatch({
        type: types.CHANGE_TIMESTAMP,
        payload: timestamp
    })
}

/**
 * Обновить статус заведения полученный из стейта
 * @param company
 */
export const updateCompany = (
  company: CompanyResponse
): AppThunkAction<AuthAction> => dispatch => {
  dispatch({
    type: types.UPDATE_COMPANY,
    payload: {
      company
    }
  })
}

/**
 * Обновить объект контрагента
 */
export const updatePartner = (
  partner: PartnerResponse,
): AppThunkAction<AuthAction> => dispatch => {
  dispatch({
    type: types.UPDATE_PARTNER,
    payload: partner,
  })
}
