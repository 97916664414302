import {StateResponse} from "../../features/state/models";
import {AppThunkAction} from "../../app/store";
import {AppStatusAction} from "../reducers/appStatus";
import * as types from '../constants/appStatus';
import {OrderResponse} from "../../features/orders/models";

/**
 * Обновить статус приложения
 */
export const updateAppStatusFromState = (
  state: StateResponse,
  serverDate: string | null,
): AppThunkAction<AppStatusAction> =>
    dispatch =>
      dispatch({
        type: types.GET_APP_STATUS_FROM_STATE,
        payload: {
            state: state,
            serverDate: serverDate,
        },
      })
