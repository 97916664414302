import * as types from '../constants/appStatus';
import {NotificationResponse, StateResponse} from "../../features/state/models";
import {RootState} from "../../app/store";
import {addHours, parse} from "date-fns";
import {enUS} from "date-fns/locale";

type OrderCountFields = 'orderTodayAcceptedCount'
  | 'orderTodayNotAcceptedCount'
  | 'orderYesterdayAcceptedCount'
  | 'orderYesterdayNotAcceptedCount'

/**
 * Показатели статуса приложения, не привязанные к конкретному разделу
 */
type AppStatusState = {
  serverDate: string | null;
  diffTime: number;
  needToUpdate: boolean;
  notificationMessage: NotificationResponse | null;
  marketingLink: string | null;
  ordersAcceptanceInfo: Record<OrderCountFields, number | null>;
};

export type AppStatusAction = {
  type: typeof types.GET_APP_STATUS_FROM_STATE;
  payload: {
    state: Pick<StateResponse,
        'needToUpdate'
        | 'notification'
        | 'notificationMessage'
        | 'marketingLink'
        | OrderCountFields>;
    serverDate: string | null;
  };
}

const initialState: AppStatusState = {
  serverDate: null,
  diffTime: 0,
  needToUpdate: false,
  notificationMessage: null,
  marketingLink: null,
  ordersAcceptanceInfo: {
    orderTodayAcceptedCount: null,
    orderTodayNotAcceptedCount: null,
    orderYesterdayAcceptedCount: null,
    orderYesterdayNotAcceptedCount: null,
  },
}

export const appStatusServerDateSelector = ({appStatus: {serverDate}}: RootState) => serverDate;
export const appStatusDiffTimeSelector = ({appStatus: {diffTime}}: RootState) => diffTime;
export const appStatusNeedToUpdateSelector = ({appStatus: {needToUpdate}}: RootState) => needToUpdate;
export const appStatusNotificationMessageSelector = ({appStatus: {notificationMessage}}: RootState) => notificationMessage;
export const appStatusMarketingLinkSelector = ({appStatus: {marketingLink}}: RootState) => marketingLink;
export const appStatusAcceptanceInfoSelector = ({appStatus: {ordersAcceptanceInfo}}: RootState) => ordersAcceptanceInfo;

/**
 * reducer статуса приложения
 */
export default function appStatus(
  state = initialState,
  action: AppStatusAction,
): AppStatusState {
  switch (action.type) {
    case types.GET_APP_STATUS_FROM_STATE:
      const serverDateTime = action.payload.serverDate ? addHours(parse(action.payload.serverDate.slice(5, -4), 'dd LLL yyyy HH:mm:ss', new Date(), {locale: enUS}), 5): new Date()
      return {
        serverDate: action.payload.serverDate,
        diffTime: (action.payload.serverDate == null) ? 0 : serverDateTime.getTime() - new Date().getTime(),
        needToUpdate: action.payload.state.needToUpdate,
        notificationMessage: action.payload.state.notificationMessage ?? null,
        marketingLink: action.payload.state.marketingLink ?? null,
        ordersAcceptanceInfo: {
          orderTodayAcceptedCount: action.payload.state.orderTodayAcceptedCount ?? null,
          orderTodayNotAcceptedCount: action.payload.state.orderTodayNotAcceptedCount ?? null,
          orderYesterdayAcceptedCount: action.payload.state.orderYesterdayAcceptedCount ?? null,
          orderYesterdayNotAcceptedCount: action.payload.state.orderYesterdayNotAcceptedCount ?? null,
        },
      }
    default:
      return state;
  }
}
