export const GET_APP_STATUS_FROM_STATE = Symbol('appStatus/GET_FROM_STATE')

/** Версии приложения, см. ниже */
const majorVersion = 1;
const minorVersion = 1;
const patch = 29;

/**
 * Версия приложения, формат основан на semver.
 * Первая цифра - мажорная, потом две - минорная, потом две - патч.
 */
export const APP_VERSION = String(majorVersion * 10000 + minorVersion * 100 + patch);
