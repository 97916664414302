import React, {memo, useMemo} from 'react';
import {DisableParamsDialogProps} from "./types";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import Button from "react-bootstrap/Button";
import Radio from "../Radio";
import {addHours, addMinutes, format} from "date-fns";
import {
    getDateInServerFormat,
    getDateTimeFormat,
    mapPickerDateToServerDate,
    UsedDateTimeFormats
} from "../../utils/dateTimeHelpers";
import DateTimePicker from "../DateTimePicker";
import TextInput from "../TextInput";
import BootstrapForm from "react-bootstrap/Form";
import * as Yup from 'yup';
import {requiredMessage} from "../../utils/validation";
import {useAppSelector} from "../../../app/store";
import {appStatusDiffTimeSelector} from "../../reducers/appStatus";

/**
 * Опции периода отключения
 */
enum DisableModes {
  HalfHour = '30minutes',
  Hour = '1hour',
  CloseWorkday = 'workday',
  Unlimited = 'unlimited',
  Exact = 'exact',
}

/**
 * Опции причины отключения
 */
enum CloseReasonModes {
  PeakHour = 'peakHour',
  TechnicalIssues = 'technicalIssues',
  Other = 'other'
}

/**
 * Диалог выбора
 */
const DisableParamsDialog: React.VFC<DisableParamsDialogProps> = ({
  title,
  description,
  hasMessage = false,
  onSubmit,
  isOpen,
  closeDialog,
  nextOpenTime
}) => {

  const {t} = useTranslation();

  const diffTime = useAppSelector(appStatusDiffTimeSelector);

  const validationSchema = useMemo(() =>
          Yup.object().shape({
            mode: Yup.string().required(),
            exactDateTime: Yup.string().when("mode", {
              is: DisableModes.Exact,
              then: Yup.string().required(requiredMessage())
            }),
            closeReasonMode: hasMessage ? Yup.string().required() : Yup.string(),
            message: hasMessage ?
                Yup.string().when("closeReasonMode", {
                  is: CloseReasonModes.Other,
                  then: Yup.string().required(requiredMessage())
                })
                :
                Yup.string(),
          }),
      [hasMessage]);

  return <Modal show={isOpen} onHide={closeDialog} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={{
        mode: '',
        exactDateTime: '',
        closeReasonMode: '',
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={({mode, exactDateTime, message, closeReasonMode}) => {

        const formMessage = hasMessage ? {message: null as string | null} : {};

        const form = {
          ...formMessage,
          dateTime: null as string | null,
        }
        
        switch (mode) {
          case DisableModes.HalfHour:
            form.dateTime = getDateInServerFormat(addMinutes(addMinutes(new Date(), 30),diffTime / 1000 / 60));
            break;
          case DisableModes.Hour:
            form.dateTime = getDateInServerFormat(addMinutes(addHours(new Date(), 1), diffTime / 1000 / 60));
            break;
          case DisableModes.CloseWorkday:
            form.dateTime = nextOpenTime === undefined ? null : nextOpenTime;
            break;
          case DisableModes.Exact:
            form.dateTime = mapPickerDateToServerDate(exactDateTime);
            break;
          case DisableModes.Unlimited:
            form.dateTime = null;
            break;
        }

        if (hasMessage) {
            switch (closeReasonMode) {
                case CloseReasonModes.PeakHour:
                    form.message = 'Час пик';
                    break;
                case CloseReasonModes.TechnicalIssues:
                    form.message = 'Технические неисправности';
                    break;
                case CloseReasonModes.Other:
                    form.message = message;
                    break;
            }
        }

        onSubmit(form);
        closeDialog();
      }}>
      {({values, setFieldValue, isValid,dirty}) => <Form>
        <Modal.Body>
          {description}

            <Radio
                checked={values.mode === DisableModes.HalfHour}
                className={description ? 'mt-3' : ''}
                id={DisableModes.HalfHour}
                label="30 минут"
                onChange={() => {
                    setFieldValue('mode', DisableModes.HalfHour);
                }}
            />

          <Radio
            checked={values.mode === DisableModes.Hour}
            className={description ? 'mt-3' : ''}
            id={DisableModes.Hour}
            label="1 час"
            onChange={() => {
              setFieldValue('mode', DisableModes.Hour);
            }}
          />

            <Radio
                checked={values.mode === DisableModes.CloseWorkday}
                className={description ? 'mt-3' : ''}
                id={DisableModes.CloseWorkday}
                label="До конца рабочего дня"
                onChange={() => {
                    setFieldValue('mode', DisableModes.CloseWorkday);
                }}
            />

          <Radio
            checked={values.mode === DisableModes.Unlimited}
            className='mt-3'
            id={DisableModes.Unlimited}
            label="Неограниченный срок"
            onChange={() => {
              setFieldValue('mode', DisableModes.Unlimited);
            }}
          />
  
          <Radio
            checked={values.mode === DisableModes.Exact}
            className='mt-3'
            id={DisableModes.Exact}
            label={<div className={"col-8"}><Field
              name='exactDateTime'
              component={DateTimePicker}
              onChange={(name: string, value: Date | null) => {
                if (value) {
                  const string = format(value, UsedDateTimeFormats.DisplayedInPicker);
                  
                  setFieldValue('exactDateTime', string);
                } else {
                  setFieldValue('exactDateTime', '')
                }
              }}
            /></div>}
            onChange={() => {
              setFieldValue('mode', DisableModes.Exact);
            }}
          />

          {hasMessage && <>
            <BootstrapForm.Label className="mt-4" htmlFor="message">{"Укажите причину закрытия заведения"}</BootstrapForm.Label>
            <Radio
              className={'mt-3'}
              id={CloseReasonModes.PeakHour}
              checked={values.closeReasonMode === CloseReasonModes.PeakHour}
              label={"Час пик"}
              onChange={() => {
                setFieldValue('closeReasonMode', CloseReasonModes.PeakHour);
              }}
            />
            <Radio
                className={'mt-3'}
                id={CloseReasonModes.TechnicalIssues}
                checked={values.closeReasonMode === CloseReasonModes.TechnicalIssues}
                label={"Технические неисправности"}
                onChange={() => {
                  setFieldValue('closeReasonMode', CloseReasonModes.TechnicalIssues);
                }}
            />
            <Radio
                className={'mt-3'}
                id={CloseReasonModes.Other}
                checked={values.closeReasonMode === CloseReasonModes.Other}
                label={"Другое"}
                onChange={() => {
                  setFieldValue('closeReasonMode', CloseReasonModes.Other);
                }}
            />
            {values.closeReasonMode === CloseReasonModes.Other &&
              <Field
                  className={'mt-3 form-control'}
                  component={TextInput}
                  type={"string"}
                  name="message"
                  placeholder={t('layout.message')}
              />
            }
          </>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={closeDialog}>
            {t('common.actionCancel')}
          </Button>
          <Button variant="outline-primary"
                  type="submit"
                  disabled={!(isValid && dirty)}
          >
            {t('common.actionSubmit')}
          </Button>
        </Modal.Footer>
      </Form>}
    </Formik>
  </Modal>
}

export default memo(DisableParamsDialog);
