import {AppThunkAction} from "../../app/store";
import {OrdersAction} from "../orders/reducer";
import {requestState} from "./api";
import {addNotify} from "../../common/actions/notify";
import {getAxiosErrorMessage} from "../../common/utils/functions";
import {changeTimestamp, updateCompany, updatePartner} from "../auth/actions";
import {AuthAction} from "../auth/reducer";
import {updateOrders} from "../orders/actions";
import {setUnansweredReviewsCount} from "../reviews/actions";
import {updateAppStatusFromState} from "../../common/actions/appStatus";
import {getDateHeader} from "../../common/utils/apiHelpers";

export const getState = (
    companyId: number,
    timestamp?: number
): AppThunkAction<OrdersAction & AuthAction> => dispatch => {
    requestState({companyId, timestamp})
        .then(({data, headers}) => {
            dispatch(updateCompany(data.company));
            dispatch(updateOrders(data.orders, getDateHeader(headers)));
            dispatch(setUnansweredReviewsCount(data.countNotAnsweredReview));
            dispatch(changeTimestamp(Number(headers["x-request-timestamp"])));
            
            if (data.partner) {
              dispatch(updatePartner(data.partner));
            }
            
            dispatch(updateAppStatusFromState(data, getDateHeader(headers)));
        })
        .catch(err => {
            dispatch(addNotify(getAxiosErrorMessage(err)));
        })
}

/**
 * Получить необходимые с самого начала показатели,
 *  - кол-во неотвеченных отзывов
 *  - наличие глобальных предупреждений
 *  - ссылка на маркетинг
 */
export const getInitiallyRequiredStateFields = (
  companyId: number,
): AppThunkAction<OrdersAction & AuthAction> => dispatch => {
  requestState({companyId})
    .then(({data, headers}) => {
      dispatch(updateCompany(data.company));
      dispatch(setUnansweredReviewsCount(data.countNotAnsweredReview));
  
      if (data.partner) {
        dispatch(updatePartner(data.partner));
      }
      
      dispatch(updateAppStatusFromState(data, getDateHeader(headers)));
    })
    .catch(err => {
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}
