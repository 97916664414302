import {format} from "date-fns";
import {ru} from "date-fns/locale";

export enum UsedDateTimeFormats {
  /**
   * Бэк-энд использует этот формат с часовым поясом.
   * Но из-за конфликта часовых поясов на девайсах этот формат не используется,
   * вместо него используется формат без часового пояса, а потом значение отступа часового пояса вручную добавляется.
   * @see getDateInServerFormat
   */
  // WithTimezone = 'yyyy-MM-dd HH:mm:ssx',
  /**  */
  ForServerWithoutTimezone = 'yyyy-MM-dd HH:mm:ss',
  /** формат, отображаемый в пикере */
  DisplayedInPicker = 'dd.MM.yyyy HH:mm',
  /** формат, отображаемый в просмотре */
  // DisplayedInView = 'HH:mm dd.MM.yyyy',
  // DisplayedInProducts = 'd MMM yyyy HH:mm'
}

/**
 * Названия месяцев в родительном падеже
 */
export const inflectedMonthNames: Record<number, string> = {
  1: "января",
  2: "февраля",
  3: "марта",
  4: "апреля",
  5: "мая",
  6: "июня",
  7: "июля",
  8: "августа",
  9: "сентября",
  10: "октября",
  11: "ноября",
  12: "декабря",
}

/**
 * Получить строку даты формата сервера, с часовым поясом Казахстана (+05).
 * Сервер ожидает, что все строки дат будут в часовом поясе +05, независимо от часового пояса девайса.
 */
export const getDateInServerFormat = (
  date: Date | number,
) => {
  return `${format(date, UsedDateTimeFormats.ForServerWithoutTimezone)}+05`;
}

/**
 * Получить строку даты формата сервера без часового пояса.
 */
export const getDateTimeFormat = (
    date: Date | number,
) => {
  return `${format(date, UsedDateTimeFormats.ForServerWithoutTimezone)}`;
}

/**
 * Преобразовать строку в ISO (yyyy-MM-dd HH:mm) в формат сервера (yyyy-MM-dd HH:mm:ssx),
 * используя фиксированный часовой пояс Казахстана
 */
export const mapISODateToServerFormat = (
  dateISO: string,
) => `${dateISO}:00+05`

/**
 * Преобразовать дату в формате DisplayedInPicker в формат как в WithTimezone, но без часового пояса.
 * Использует манипуляции со строками, так как Date.parse и new Date() могут работать по-разному в различных браузерах.
 * Например, в Chrome new Date('01.09.2022') может парситься как 9 января 2022 (по формату мм.дд.гггг),
 * а в Firefox эта же дата парсится как 1 сентября 2022.
 * Но если дата будет в ISO (гггг-мм-дд), то такие различия не будут проявляться.
 * @param date - дата в формате DisplayedInPicker
 * @see UsedDateTimeFormats - форматы
 */
export const mapPickerFormatDateToISO = (
  date: string
) => `${date.slice(6, 10)}-${date.slice(3, 5)}-${date.slice(0, 2)}${date.slice(10)}`;

/**
 * Преобразовать строку даты в формате DisplayedInPicker в формат WithTimezone
 */
export const mapPickerDateToServerDate = (
  pickerDate: string
) => mapISODateToServerFormat(mapPickerFormatDateToISO(pickerDate));

/**
 * Парсинг даты, предполагая, что она будет в формате WithTimezone (yyyy-MM-dd HH:mm:ssx).
 * Вручную, чтобы игнорировать возможную разницу в часовых поясах между сервером и девайсом.
 */
export const parseServerDate = (
  input: string,
): Date => {
  
  const year = Number(input.slice(0, 4));
  const month = Number(input.slice(5, 7));
  const day = Number(input.slice(8, 10));
  
  const hours = Number(input.slice(11, 13));
  const minutes = Number(input.slice(14, 16));
  const seconds = Number(input.slice(17, 19));
  
  return new Date(
    year,
    /** здесь ожидается индекс месяца, от 0 до 11, а не его номер в календаре */
    month - 1,
    day,
    hours,
    minutes,
    seconds,
  )
}

type GetDateTimeTupleParams = {
  todayLocale?: string;
  previousDayLocale?: string;
  nextDayLocale?: string;
  dateFormat?: string;
  withoutYear?: boolean;
}

/**
 * Сформировать кортеж [дата, время] из строки формата api.
 * Если дата сегодня/вчера/завтра, то показать строки локализации вместо даты.
 */
export const getDateTimeTuple = (
  dateTime?: string | null,
  {
    todayLocale = "Сегодня",
    previousDayLocale = "Вчера",
    nextDayLocale = "Завтра",
    withoutYear = false,
  }: GetDateTimeTupleParams = {},
): [string, string] | null => {
  
  if (!dateTime) {
    return null;
  }
  
  const dateObject = parseServerDate(dateTime);
  const time = format(dateObject, 'HH:mm');
  
  const isToday = (new Date(dateObject)).setHours(0,0,0,0) === (new Date()).setHours(0,0,0,0);
  
  if (isToday) {
    return [todayLocale, time];
  }
  
  const isPreviousDay = (new Date(dateObject).setHours(0,0,0,0) === new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0,0,0,0));
  
  if (isPreviousDay) {
    return [previousDayLocale, time];
  }
  
  const isNextDay = (new Date(dateObject).setHours(0,0,0,0) === new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0));
  
  if (isNextDay) {
    return [nextDayLocale, time];
  }
  
  const dateFormat = withoutYear
    ? 'd MMMM'
    : 'd MMMM yyyy';
  
  return [format(dateObject, dateFormat, {locale: ru}), time];
}
